import React, { useEffect, useContext, useState } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Svg,
  Rect,
  Font,
} from "@react-pdf/renderer";
import AuthContext from "../../Context/AuthContext";
import { PDFViewer } from "@react-pdf/renderer";
import { useLocation } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import { useTranslation } from "react-i18next";
import axios from "axios";

function PdfReport() {
  const [isLoading, setIsLoading] = useState(true);
  const [reportData, setReportData] = useState({});

  const { state } = useContext(AuthContext);

  const location = useLocation();

  const { t } = useTranslation();

  let person = location.state.person;
  let report = location.state.report;

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/reports/data`,
        data: {
          reportId: report,
          personId: person,
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      console.log(response.data);
      setReportData(response.data.reportData);
      setIsLoading(false);
    }
    fetch();
  }, []);

  const relationShipName = (relationship) => {
    if (relationship === "client") {
      if (state.language === "es") {
        return "Clientes";
      } else if (state.language === "en") {
        return "Clients";
      }
    } else if (relationship === "peer") {
      if (state.language === "es") {
        return "Homólogos";
      } else if (state.language === "en") {
        return "Peers";
      }
    } else if (relationship === "boss") {
      if (state.language === "es") {
        return "Jefe";
      } else if (state.language === "en") {
        return "Boss";
      }
    } else if (relationship === "self") {
      if (state.language === "es") {
        return "Auto";
      } else if (state.language === "en") {
        return "Self";
      }
    } else if (relationship === "subordinate") {
      if (state.language === "es") {
        return "Subordinados";
      } else if (state.language === "en") {
        return "Subordinates";
      }
    }
  };

  const getColor = (value) => {
    let reference = Number(value);
    if (reference > 112) {
      return {
        color: "#5d78ff",
        backgroundColor: "rgba(93, 120, 255, 0.3)",
        padding: 3,
        borderRadius: 5,
        fontSize: 14,
      };
    } else if (reference <= 112 && reference >= 90) {
      return {
        backgroundColor: "rgba(10, 187, 135, 0.3)",
        color: "#0abb87",
        padding: 3,
        borderRadius: 5,
        fontSize: 14,
      };
    } else if (reference < 90 && reference >= 70) {
      return {
        backgroundColor: "rgba(255, 184, 34, 0.2)",
        color: "#e5991f",
        padding: 3,
        borderRadius: 5,
        fontSize: 14,
      };
    } else if (reference < 70) {
      return {
        color: "red",
        backgroundColor: "rgba(253, 57, 122, 0.1)",
        padding: 3,
        borderRadius: 5,
        fontSize: 14,
      };
    }
  };

  Font.register({
    family: "Lato",
    fonts: [
      {
        src: "https://fonts.gstatic.com/s/lato/v24/S6uyw4BMUTPHvxk6XweuBCY.ttf",
        fontWeight: "regular",
      },
    ],
  });

  const styles = StyleSheet.create({
    page: {
      flex: 1,
      backgroundColor: "#efefef",
      fontFamily: "Lato",
    },
    generalData: {
      backgroundColor: "#FFFFFF",
      padding: 20,
      borderRadius: 5,
      margin: 10,
      borderColor: "#DFDFDF",
      borderStyle: "solid",
      borderWidth: 1,
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    name: {
      fontSize: 20,
      fontWeight: "bold",
    },
    profile: {
      fontSize: 15,
    },
    score: {
      fontSize: 30,
      fontWeight: "bold",
    },
    objectivePanel: {
      backgroundColor: "#FFFFFF",
      padding: 20,
      borderRadius: 5,
      margin: 10,
      borderColor: "#DFDFDF",
      borderStyle: "solid",
      borderWidth: 1,
    },
    objectiveDescription: {
      fontSize: 15,
      marginBottom: 10,
      alignSelf: "center",
    },
    objectiveComments: {
      flexDirection: "row",
      justifyContent: "space-between",
      gap: 25,
    },
    objectiveCommentContainer: {
      flex: 1,
    },
    commentTitle: {
      fontSize: 14,
      fontWeight: "bold",
      marginBottom: 5,
    },
    comment: {
      fontSize: 12,
      marginBottom: 15,
    },
    objectiveScore: {
      flexDirection: "row",
      justifyContent: "space-between",
    },
    approverScore: {
      fontSize: 25,
    },
    progressBar: {
      flex: 1,
    },
    viewer: {
      width: window.innerWidth * 0.8, //the pdf viewer will take up all of the width and height
      height: window.innerHeight * 0.8,
    },
    perspectivesPanel: {
      backgroundColor: "#FFFFFF",
      padding: 20,
      borderRadius: 5,
      margin: 10,
      borderColor: "#DFDFDF",
      borderStyle: "solid",
      borderWidth: 1,
    },
    perspectivesHeader: {
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: 10,
      color: "#000000",
    },
    perspectivesContent: {
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: 10,
      color: "#000000",
      padding: 5,
    },
    perspectivesContentEven: {
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: 10,
      color: "#000000",
      backgroundColor: "#dfdfdf",
      padding: 5,
    },
    competencyPanel: {
      backgroundColor: "#FFFFFF",
      padding: 20,
      borderRadius: 5,
      margin: 10,
      borderColor: "#DFDFDF",
      borderStyle: "solid",
      borderWidth: 1,
    },
    competencyHeader: {
      flexDirection: "row",
      justifyContent: "space-between",
      padding: 5,
    },
    competencyDescription: {
      width: "85%",
    },
    behaviorPanel: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      padding: 5,
    },
    behaviorPanelEven: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      backgroundColor: "#dfdfdf",
      padding: 5,
    },
    behaviorDescription: {
      width: "85%",
    },
    openquestionPanel: {
      backgroundColor: "#FFFFFF",
      padding: 20,
      borderRadius: 5,
      margin: 10,
      borderColor: "#DFDFDF",
      borderStyle: "solid",
      borderWidth: 1,
    },
    openquestionHeader: {},
    openquestionComments: {
      flexDirection: "row",
      flexWrap: "wrap",
    },
    standardText: {
      fontSize: 14,
      marginBottom: 4,
      color: "#555555",
    },
    grade: {
      fontSize: 14,
      color: "#333333",
    },
    feedbackComment: {
      color: "#FFFFFF",
      backgroundColor: "#555",
      padding: 3,
      borderRadius: 5,
      fontSize: 12,
      margin: 3,
    },
    scoresPanel: {
      backgroundColor: "#FFFFFF",
      padding: 20,
      borderRadius: 5,
      margin: 10,
      borderColor: "#DFDFDF",
      borderStyle: "solid",
      borderWidth: 1,
      flexDirection: "row",
      justifyContent: "space-between",
    },
    evaluation: {
      alignItems: "center",
    },
    evaluationTitle: {
      fontSize: 15,
    },
    evaluationValue: {
      fontSize: 12,
    },
    evaluationWeight: {
      fontSize: 12,
    },
  });

  const generateContent = (data) => {
    if (data.period.type === "objetivos") {
      return data.objectives.map((objective) => (
        <View style={styles.objectivePanel} key={objective._id}>
          <Text style={styles.objectiveDescription}>
            {objective.description}
          </Text>
          <View style={styles.objectiveComments}>
            <View style={styles.objectiveCommentContainer}>
              <Text style={styles.commentTitle}>
                {t("Reportes.Reporte.PDF.ComentarioUsuario")}
              </Text>
              <Text style={styles.comment}>{objective.userComment}</Text>
            </View>
            <View style={styles.objectiveCommentContainer}>
              <Text style={styles.commentTitle}>
                {t("Reportes.Reporte.PDF.ComentarioJefe")}
              </Text>
              <Text style={styles.comment}>{objective.approverComment}</Text>
            </View>
          </View>
          <View style={styles.objectiveScore}>
            <View style={styles.progressBar}>
              <Svg
                height="20"
                width="95%"
                viewBox="0 0 100 100"
                preserveAspectRatio="none"
              >
                <Rect
                  x="0"
                  y="0"
                  rx="15"
                  height="100%"
                  width="100%"
                  fill="#e9ecef"
                />
                <Rect
                  x="0"
                  y="0"
                  rx="15"
                  height="100%"
                  width={`${objective.approverScore}%`}
                  fill="#0d6dfd"
                />
              </Svg>
            </View>
            <View>
              <Text style={styles.approverScore}>
                {objective.approverScore}
              </Text>
            </View>
          </View>
        </View>
      ));
    } else if (data.period.type === "competencias") {
      return (
        <>
          <View style={styles.perspectivesPanel}>
            <View style={styles.perspectivesHeader}>
              <View>
                <Text>{t("Reportes.Reporte.PDF.Perspectiva")}</Text>
              </View>
              <View>
                <Text>{t("Reportes.Reporte.PDF.Calificacion")}</Text>
              </View>
            </View>
            {data.relationshipData.map((relationship, index) => (
              <View
                style={
                  index % 2 === 0
                    ? styles.perspectivesContent
                    : styles.perspectivesContentEven
                }
                key={relationship.relationship}
              >
                <Text style={styles.standardText}>
                  {relationShipName(relationship.relationship)}
                </Text>
                <Text style={styles.grade}>
                  {relationship.grade.toFixed(2)}
                </Text>
              </View>
            ))}
          </View>
          {data.allBehaviorsData.map((competency) => (
            <View style={styles.competencyPanel}>
              <View style={styles.competencyHeader}>
                <View style={styles.competencyDescription}>
                  <Text>
                    {state.language === "es"
                      ? competency.competency
                      : competency.competency_english}
                  </Text>
                  <Text style={styles.standardText}>
                    {state.language === "es"
                      ? competency.description
                      : competency.description_english}
                  </Text>
                </View>
                <View>
                  <Text>{t("Reportes.Reporte.PDF.Total")}</Text>
                  <Text style={getColor(competency.grade)}>
                    {competency.revisedGrade.toFixed(2)}
                  </Text>
                </View>
              </View>
              {competency.behaviors.map((behavior, index) => (
                <View
                  style={
                    index % 2 === 0
                      ? styles.behaviorPanelEven
                      : styles.behaviorPanel
                  }
                >
                  <View style={styles.behaviorDescription}>
                    <Text>
                      {state.langauge === "es"
                        ? behavior.name
                        : behavior.name_english}
                    </Text>
                    <Text style={styles.standardText}>
                      {state.language === "es"
                        ? behavior.description
                        : behavior.description_english}
                    </Text>
                  </View>
                  <View>
                    <Text style={getColor(behavior.grade)}>
                      {behavior.revisedGrade.toFixed(2)}
                    </Text>
                    <Text></Text>
                  </View>
                </View>
              ))}
            </View>
          ))}
          {data.openQuestions.map((question) => (
            <View style={styles.openquestionPanel}>
              <View style={styles.openquestionHeader}>
                <Text>
                  {state.language === "es"
                    ? question.description
                    : question.description_english}
                </Text>
              </View>
              <View style={styles.openquestionComments}>
                {question.readyComments.map((answer) => (
                  <Text style={styles.feedbackComment}>{answer} </Text>
                ))}
              </View>
            </View>
          ))}
        </>
      );
    }
  };

  if (isLoading === true) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  } else {
    return (
      <PDFViewer style={styles.viewer}>
        <Document>
          <Page size="LETTER" style={styles.page}>
            {reportData.periodsArray ? (
              <>
                <View style={styles.generalData}>
                  <View>
                    <Text style={styles.name}>
                      {reportData.userData.firstName}{" "}
                      {reportData.userData.lastName}
                    </Text>
                    <Text style={styles.profile}>
                      {state.language === "es"
                        ? reportData.userData.profileName
                        : reportData.userData.profileNameEnglish}
                    </Text>
                  </View>
                  <View>
                    <Text style={styles.score}>
                      {reportData.finalGrade.toFixed(2)}
                    </Text>
                  </View>
                </View>
                <View style={styles.scoresPanel}>
                  {reportData.scoresPanel.map((item) => (
                    <View style={styles.evaluation} key={item.id}>
                      <Text
                        numberOfLines={1}
                        adjustsFontSizeToFit
                        style={styles.evaluationTitle}
                      >
                        {item.period}
                      </Text>
                      <Text
                        numberOfLines={1}
                        adjustsFontSizeToFit
                        style={styles.evaluationWeight}
                      >
                        {t("Reportes.Reporte.PDF.Ponderacion")} {item.weight}
                      </Text>
                      <Text
                        numberOfLines={1}
                        adjustsFontSizeToFit
                        style={styles.evaluationValue}
                      >
                        {t("Reportes.Reporte.PDF.Calificacion")}{" "}
                        {item.finalGrade.toFixed(2)}
                      </Text>
                    </View>
                  ))}
                </View>
                {reportData.periodsArray.map((item) => generateContent(item))}
              </>
            ) : Object.keys(reportData).length === 0 ? (
              <></>
            ) : (
              <>
                <View style={styles.generalData}>
                  <View>
                    <Text style={styles.name}>
                      {reportData.userData.firstName}{" "}
                      {reportData.userData.lastName}
                    </Text>
                    <Text style={styles.profile}>
                      {state.language === "es"
                        ? reportData.userData.profileName
                        : reportData.userData.profileNameEnglish}
                    </Text>
                  </View>
                  <View>
                    <Text style={styles.score}>
                      {reportData.finalGrade.toFixed(2)}
                    </Text>
                  </View>
                </View>
                {generateContent(reportData)}
              </>
            )}
          </Page>
        </Document>
      </PDFViewer>
    );
  }
}

export default PdfReport;

import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import Table from "react-bootstrap/Table";
import { Container, Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import "./TalentMap.css";

function TalentMap() {
  const [isLoading, setIsLoading] = useState(true);
  const [headerTitles, setHeaderTitles] = useState([]);
  const [headerGrades, setHeaderGrades] = useState([]);
  const [bodyGrades, setBodyGrades] = useState([]);
  const [categories, setCategories] = useState([]);
  const [filter, setFilter] = useState("");

  const { rid } = useParams();

  const { state } = useContext(AuthContext);

  const { t } = useTranslation();

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/reports/talent/map/${rid}`,
        data: {
          filter: "",
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setHeaderTitles(response.data.headerTitles);
      setHeaderGrades(response.data.headerGrades);
      setBodyGrades(response.data.tableBodyGrades);
      setCategories(response.data.categories);
      setIsLoading(false);
    }
    fetch();
  }, []);

  const filterMap = async (e) => {
    setIsLoading(true);
    setFilter(e.target.value);
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_BACKEND_URL}/reports/talent/map/${rid}`,
      data: {
        filter: e.target.value,
      },
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    });
    setHeaderTitles(response.data.headerTitles);
    setHeaderGrades(response.data.headerGrades);
    setBodyGrades(response.data.tableBodyGrades);
    setIsLoading(false);
  };

  const getStatus = (value) => {
    if (value === " ") {
      return "blank-label";
    } else {
      let reference = Number(value);
      if (reference > 100) {
        return "blue-label";
      } else if (reference <= 100 && reference >= 90) {
        return "green-label";
      } else if (reference < 90 && reference >= 70) {
        return "yellow-label";
      } else if (reference < 70) {
        return "red-label";
      }
    }
  };

  const createColGroups = (array) => {
    let cols = [];
    cols.push(<col key={-2} className="name" />);
    for (let index = -1; index < array.length; index++) {
      cols.push(<col key={index} className="gap" />);
    }
    let content = <colgroup>{cols.map((item) => item)}</colgroup>;
    return content;
  };

  if (isLoading === true) {
    return (
      <>
        <Spinner animation="border" variant="primary" role="status"></Spinner>
        <span> {t("Reportes.Calculando")}</span>
      </>
    );
  } else {
    return (
      <Container fluid>
        <Row className="flex-row flex-nowrap align-items-center">
          <Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Select
                  className="mb-3"
                  name="categories"
                  onChange={(e) => filterMap(e)}
                  value={filter}
                >
                  <option value={""}>{t("Reportes.Filtar")}</option>
                  {categories.map((item) => (
                    <option key={item._id} id={item._id} value={item._id}>
                      {state.language === "es" ? item.name : item.name_english}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col className="talent-map-container">
              <Table responsive>
                {createColGroups(headerTitles)}
                <thead>
                  <tr>
                    <th>
                      <div></div>
                    </th>
                    <th>
                      <div></div>
                    </th>
                    {headerTitles.map((item) => (
                      <th key={item.id}>
                        <div className={`${item.class} center`}>
                          {state.language === "es"
                            ? item.title
                            : item.title_english}
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div></div>
                    </td>
                    <td>
                      <div></div>
                    </td>
                    {headerGrades.map((item) => (
                      <td key={item.id}>
                        <div className={`${getStatus(item.grade)}`}>
                          {item.grade}
                        </div>
                      </td>
                    ))}
                  </tr>
                  {bodyGrades.map((item) => {
                    return (
                      <tr key={item.id}>
                        {item.values.map((element) => {
                          return (
                            <td key={element.id} className="test">
                              <div className={`${getStatus(element.value)}`}>
                                {element.value}
                              </div>
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Col>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default TalentMap;

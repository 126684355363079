import React from "react";
import CompetencyPanel from "./CompetencyPanel";

function CompetenciesPanelsList({ data, status, language }) {
  return data.map((item) => (
    <CompetencyPanel
      key={item.competency}
      competencyName={item.competency}
      competencyNameEnglish={item.competency_english}
      competencyDescription={item.description}
      competencyDescriptionEnglish={item.description_english}
      competencyGrade={item.revisedGrade}
      behaviors={item.behaviors}
      status={status}
      language={language}
    />
  ));
}

export default CompetenciesPanelsList;

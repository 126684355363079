import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";
import "./CompetencyPanel.css";

function CompetencyPanel({
  competencyName,
  competencyNameEnglish,
  competencyDescription,
  competencyDescriptionEnglish,
  competencyGrade,
  status,
  behaviors,
  language,
}) {
  const { t } = useTranslation();

  const classTypeSelectionByRelationship = (behavior, label) => {
    let revisedGrade = null;
    if (label === "total") {
      revisedGrade = behavior.revisedGrade;
    } else {
      let item = behavior.relationships.find(
        (item) => item.relationship === label
      );
      if (item) {
        revisedGrade = (item.mean / item.expected) * 100;
      } else {
        revisedGrade = null;
      }
    }

    if (revisedGrade === null) {
      return "white-label";
    } else if (revisedGrade < 71) {
      return "red-label";
    } else if (revisedGrade >= 71 && revisedGrade < 90) {
      return "yellow-label";
    } else if (revisedGrade >= 90 && revisedGrade < 110) {
      return "green-label";
    } else if (revisedGrade >= 110) {
      return "blue-label";
    } else {
      return "white-label";
    }
  };

  const getBehaviorRelationshipValue = (behavior, label) => {
    if (label === "total") {
      return behavior.revisedGrade.toFixed(2);
    } else {
      let item = behavior.relationships.find(
        (item) => item.relationship === label
      );
      if (item) {
        return ((item.mean / item.expected) * 100).toFixed(2);
      } else {
        return "N/A";
      }
    }
  };

  const getBehaviorRelationshipValueTotal = (relationship) => {
    let total = 0;
    let counter = 0;
    let expected = 0;
    for (const item of behaviors) {
      let behavior = item.relationships.find(
        (item) => item.relationship === relationship
      );
      if (behavior) {
        total += behavior.mean;
        expected += behavior.expected;
        counter += 1;
      }
    }
    let result = ((total / counter / (expected / counter)) * 100).toFixed(2);
    if (result === "NaN") {
      return "N/A";
    } else {
      return result;
    }
  };

  const getBehaviorRelationshipClassTypeTotal = (relationship) => {
    let total = 0;
    let counter = 0;
    let expected = 0;
    for (const item of behaviors) {
      let behavior = item.relationships.find(
        (item) => item.relationship === relationship
      );
      if (behavior) {
        total += behavior.mean;
        expected += behavior.expected;
        counter += 1;
      }
    }

    let revisedGrade = (total / counter / (expected / counter)) * 100;

    if (isNaN(revisedGrade)) {
      return "white-label";
    } else if (revisedGrade < 71) {
      return "red-label";
    } else if (revisedGrade >= 71 && revisedGrade < 90) {
      return "yellow-label";
    } else if (revisedGrade >= 90 && revisedGrade < 110) {
      return "green-label";
    } else if (revisedGrade >= 110) {
      return "blue-label";
    } else {
      return "white-label";
    }
  };

  const getCompetencyValueTotal = () => {
    return competencyGrade.toFixed(2);
  };

  const getCompetencyClassTypeTotal = () => {
    let total = 0;
    let counter = 0;
    let expected = 0;
    for (const item of behaviors) {
      for (const behavior of item.relationships) {
        total += behavior.mean;
        expected += behavior.expected;
        counter += 1;
      }
    }

    let revisedGrade = (total / counter / (expected / counter)) * 100;

    if (revisedGrade < 71) {
      return "red-label";
    } else if (revisedGrade >= 71 && revisedGrade < 90) {
      return "yellow-label";
    } else if (revisedGrade >= 90 && revisedGrade < 110) {
      return "green-label";
    } else if (revisedGrade >= 110) {
      return "blue-label";
    }
  };

  return (
    <Col className="competency-panel mb-3">
      <Col className="competency-panel-header">
        {status === true ? (
          <Row className="align-items-center">
            <Col lg={6}>
              <h5>
                {language === "es" ? competencyName : competencyNameEnglish}
              </h5>
              <p>
                {language === "es"
                  ? competencyDescription
                  : competencyDescriptionEnglish}
              </p>
            </Col>
            <Col lg={1} className="table-header">
              <strong>{t("Reportes.Jefe")}</strong>
            </Col>
            <Col lg={1} className="table-header">
              <strong>{t("Reportes.Auto")}</strong>
            </Col>
            <Col lg={1} className="table-header">
              <strong>{t("Reportes.Homologos")}</strong>
            </Col>
            <Col lg={1} className="table-header">
              <strong>{t("Reportes.Subordinados")}</strong>
            </Col>
            <Col lg={1} className="table-header">
              <strong>{t("Reportes.Clientes")}</strong>
            </Col>
            <Col lg={1} className="table-header">
              <strong>{t("Reportes.Total")}</strong>
            </Col>
          </Row>
        ) : (
          <Row className="align-items-center">
            <Col lg={6}>
              <h5>
                {language === "es" ? competencyName : competencyNameEnglish}
              </h5>
              <p>
                {language === "es"
                  ? competencyDescription
                  : competencyDescriptionEnglish}
              </p>
            </Col>
            <Col lg={1}></Col>
            <Col lg={1}></Col>
            <Col lg={1}></Col>
            <Col lg={1}></Col>
            <Col lg={1}></Col>
            <Col lg={1}>
              <strong>{t("Reportes.Total")}</strong>
            </Col>
          </Row>
        )}
      </Col>
      <Col className="competency-panel-behaviors">
        {status === true
          ? behaviors.map((behavior) => (
              <Row
                key={behavior.name}
                className="align-items-center behavior-details"
              >
                <Col lg={6}>
                  <h5>
                    {language === "es" ? behavior.name : behavior.name_english}
                  </h5>
                  <p>
                    {language === "es"
                      ? behavior.description
                      : behavior.description_english}
                  </p>
                </Col>
                <Col lg={1}>
                  <span
                    className={classTypeSelectionByRelationship(
                      behavior,
                      "boss"
                    )}
                  >
                    {getBehaviorRelationshipValue(behavior, "boss")}
                  </span>
                </Col>
                <Col lg={1}>
                  <span
                    className={classTypeSelectionByRelationship(
                      behavior,
                      "self"
                    )}
                  >
                    {getBehaviorRelationshipValue(behavior, "self")}
                  </span>
                </Col>
                <Col lg={1}>
                  <span
                    className={classTypeSelectionByRelationship(
                      behavior,
                      "peer"
                    )}
                  >
                    {getBehaviorRelationshipValue(behavior, "peer")}
                  </span>
                </Col>
                <Col lg={1}>
                  <span
                    className={classTypeSelectionByRelationship(
                      behavior,
                      "subordinate"
                    )}
                  >
                    {getBehaviorRelationshipValue(behavior, "subordinate")}
                  </span>
                </Col>
                <Col lg={1}>
                  <span
                    className={classTypeSelectionByRelationship(
                      behavior,
                      "client"
                    )}
                  >
                    {getBehaviorRelationshipValue(behavior, "client")}
                  </span>
                </Col>
                <Col lg={1}>
                  <span
                    className={classTypeSelectionByRelationship(
                      behavior,
                      "total"
                    )}
                  >
                    {getBehaviorRelationshipValue(behavior, "total")}
                  </span>
                </Col>
              </Row>
            ))
          : behaviors.map((behavior) => (
              <Row
                key={behavior.name}
                className="align-items-center behavior-details"
              >
                <Col lg={6}>
                  <h5>
                    {language === "es" ? behavior.name : behavior.name_english}
                  </h5>
                  <p>
                    {language === "es"
                      ? behavior.description
                      : behavior.description_english}
                  </p>
                </Col>
                <Col lg={1}></Col>
                <Col lg={1}></Col>
                <Col lg={1}></Col>
                <Col lg={1}></Col>
                <Col lg={1}></Col>
                <Col lg={1}>
                  <span
                    className={classTypeSelectionByRelationship(
                      behavior,
                      "total"
                    )}
                  >
                    {getBehaviorRelationshipValue(behavior, "total")}
                  </span>
                </Col>
              </Row>
            ))}
      </Col>
      <Col className="competency-panel-totals">
        {status === true ? (
          <Row className="align-items-center justify-content-center">
            <Col lg={6}>
              <h5>{t("Reportes.Total")}</h5>
            </Col>
            <Col lg={1}>
              <span className={getBehaviorRelationshipClassTypeTotal("boss")}>
                {getBehaviorRelationshipValueTotal("boss")}
              </span>
            </Col>
            <Col lg={1}>
              <span className={getBehaviorRelationshipClassTypeTotal("self")}>
                {getBehaviorRelationshipValueTotal("self")}
              </span>
            </Col>
            <Col lg={1}>
              <span className={getBehaviorRelationshipClassTypeTotal("peer")}>
                {getBehaviorRelationshipValueTotal("peer")}
              </span>
            </Col>
            <Col lg={1}>
              <span
                className={getBehaviorRelationshipClassTypeTotal("subordinate")}
              >
                {getBehaviorRelationshipValueTotal("subordinate")}
              </span>
            </Col>
            <Col lg={1}>
              <span className={getBehaviorRelationshipClassTypeTotal("client")}>
                {getBehaviorRelationshipValueTotal("client")}
              </span>
            </Col>
            <Col lg={1}>
              <span className={getCompetencyClassTypeTotal()}>
                {getCompetencyValueTotal()}
              </span>
            </Col>
          </Row>
        ) : (
          <Row className="align-items-center">
            <Col lg={6}>
              <h5>{t("Reportes.Total")}</h5>
            </Col>
            <Col lg={1}></Col>
            <Col lg={1}></Col>
            <Col lg={1}></Col>
            <Col lg={1}></Col>
            <Col lg={1}></Col>
            <Col lg={1}>
              <span className={getCompetencyClassTypeTotal()}>
                {getCompetencyValueTotal()}
              </span>
            </Col>
          </Row>
        )}
      </Col>
    </Col>
  );
}

export default CompetencyPanel;

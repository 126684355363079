import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Breadcrumbs from "../../Navigation/Components/Breadcrumbs";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import "./PersonCategories.css";
import {
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";

function PersonCategories() {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [personData, setPersonData] = useState("");

  const { state } = useContext(AuthContext);

  let { pid } = useParams();

  const { t } = useTranslation();

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/users/categories`,
        data: {
          userId: pid,
          organization: state.organization,
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setPersonData(response.data.userData);
      setSelectedCategories(response.data.selectedCategories);
      setCategories(response.data.categories);
      setIsLoading(false);
    }
    fetch();
  }, []);

  const navigate = useNavigate();

  const handleSelection = (id) => {
    let buffer = categories.find((element) => element._id === id);

    let newCategoriesArray = categories.filter((element) => {
      return element._id !== id;
    });

    setSelectedCategories([...selectedCategories, buffer]);
    setCategories(newCategoriesArray);
  };

  const handleBackToPool = (id) => {
    let buffer = selectedCategories.find((element) => element._id === id);

    let newSelectedCategoriesArray = selectedCategories.filter((element) => {
      return element._id !== id;
    });

    setCategories([...categories, buffer]);
    setSelectedCategories(newSelectedCategoriesArray);
  };

  const handleSubmit = async (e) => {
    await axios({
      method: "put",
      url: `${process.env.REACT_APP_BACKEND_URL}/users/categories/update`,
      data: {
        selectedCategories: selectedCategories,
        userId: pid,
      },
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    });
    navigate("/people");
  };

  let breadcrumbs = [
    {
      title: "Personas",
      path: "/people",
    },
  ];

  if (isLoading === true) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  } else {
    return (
      <Container fluid>
        <Row>
          <Breadcrumbs data={breadcrumbs} />
        </Row>
        <Row>
          <Col lg={9}>
            <Form>
              <Col className="header">
                <h5>{personData.firstName + " " + personData.lastName}</h5>
              </Col>
              <Col className="selected-categories">
                {selectedCategories.map((element) => (
                  <Container key={element._id} fluid className="category">
                    <Row className="align-items-center">
                      <Col lg={10}>
                        <h5>{element.name}</h5>
                      </Col>
                      <Col lg={2} className="center">
                        <FontAwesomeIcon
                          icon={faChevronRight}
                          onClick={() => handleBackToPool(element._id)}
                        />
                      </Col>
                    </Row>
                  </Container>
                ))}
              </Col>
              <Col className="person-buttons">
                <Button className="primary" onClick={handleSubmit}>
                  {t("Categorias.Assignment.Guardar")}
                </Button>
                <Link to="/people">
                  <Button className="danger">
                    {t("Categorias.Assignment.Cancelar")}
                  </Button>
                </Link>
              </Col>
            </Form>
          </Col>
          <Col lg={3} className="categories-pool">
            <Col>
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Control type="text" placeholder="Buscar persona" />
              </Form.Group>
            </Col>
            <Col>
              {categories.map((element) => (
                <Row
                  key={element._id}
                  className="align-items-center categories-pool-item"
                >
                  <Col lg={2}>
                    <FontAwesomeIcon
                      icon={faChevronLeft}
                      onClick={() => handleSelection(element._id)}
                    />
                  </Col>
                  <Col lg={10} className="right">
                    <Col>
                      <h5>{element.name}</h5>
                    </Col>
                  </Col>
                </Row>
              ))}
            </Col>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default PersonCategories;
